<template>
  <div class="fill-height">
    <v-container
      v-if="!showSignupOnboarding"
      id="register"
      tag="section"
      class="fill-height justify-center"
    >
      <v-row justify="center" class="fill-height">
        <v-col
          v-if="$vuetify.breakpoint.mdAndUp"
          class="fill-height d-flex align-center"
          md="6"
          xl="4"
        >
          <v-img
            :src="require(`@/assets/login_illu.svg`)"
            contain
            max-width="400px"
          />
        </v-col>
        <v-col
          cols="12"
          md="6"
          xl="4"
          class="d-flex align-center justify-center fill-height"
        >
          <register-form @registered="formSentEventHandler" />
        </v-col>
      </v-row>
    </v-container>

    <transition name="fade">
      <c-signup-onboarding
        v-if="showSignupOnboarding"
        :tenant-email="createdUser.email"
      />
    </transition>

    <template v-if="showCapterraTracker">
      <img
        src="https://ct.capterra.com/capterra_tracker.gif?vid=2200001&vkey=48c9dee2bbd0731a269e24c27a51bad6"
      />
    </template>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import api from "@/lib/calendesk-js-library/api/api";
import {
  errorNotification,
  successNotification,
} from "@/lib/calendesk-js-library/tools/notification";
import CSignupOnboarding from "@/components/CSignUpOnboarding/CSignUpOnboarding";
import sharedActions from "@/lib/calendesk-js-library/mixins/sharedActions";

export default {
  name: "CRegister",
  components: {
    CSignupOnboarding,
    RegisterForm: () => import("./forms/RegisterForm"),
  },
  mixins: [sharedActions],
  data() {
    return {
      showCapterraTracker: false,
      email: "",
      password: "",
      isSendingLinkAgain: false,
      loading: null,
      createdUser: null,
      isRedirecting: false,
      showSignupOnboarding: false,
    };
  },
  computed: {
    ...mapGetters({
      firstRouteRedirectName: "auth/getFirstRouteRedirectName",
    }),
  },
  created() {
    if (this.isUserLogged) {
      this.$router.push({ name: this.firstRouteRedirectName });
    }
  },
  methods: {
    ...mapActions({
      loginUserAndStoreSession: "auth/loginUserAndStoreSession",
      fetchConfiguration: "setup/fetchConfiguration",
      setSignupAppBarVisibilityStatus: "setup/setSignupAppBarVisibilityStatus",
    }),
    loginUser() {
      this.email = this.createdUser.email;
      this.password = this.createdUser.password;
      this.loading = setInterval(() => {
        this.login();
      }, 5000);
    },
    openSignupOnboarding() {
      this.setSignupAppBarVisibilityStatus(false);
      this.showSignupOnboarding = true;
    },
    formSentEventHandler(data) {
      this.showCapterraTracker = true;
      this.createdUser = data.user;
      this.captchaToken = data.token;

      this.loginUser();
      this.openSignupOnboarding();
    },
    sendActivateEmail() {
      this.isSendingLinkAgain = true;
      api
        .resendActivateEmail(this.email)
        .then(() => {
          successNotification("account_activation_resend_link_succeeded");
        })
        .catch((error) => {
          errorNotification("account_activation_resend_link_failed", error);
        })
        .finally(() => {
          this.isSendingLinkAgain = false;
        });
    },
    login() {
      if (!this.isUserLogged) {
        this.loginUserAndStoreSession({
          email: this.email,
          password: this.password,
          captchaToken: this.captchaToken,
        })
          .then(() => {
            this.fetchConfiguration();
            clearInterval(this.loading);
            this.loading = null;
          })
          .catch((error) => {
            this.$log.error("Login Error Occurred", error);
          });
      }
    },
  },
};
</script>
